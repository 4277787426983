// export const siteConfig = {
//     name: "PodSearch",
//     url: "https://podsearch.io",
//     description:
//       "Search your favorite Podcasts.",
//     links: {
//     },
//   }

export const siteConfig = {
  name: "SearchTMS",
  url: "https://searchtms.com",
  description:
    "Search the backlog of The Morning Stream episodes.",
  links: {
  },
}


  export type SiteConfig = typeof siteConfig
  