import { Link, useLocation } from "react-router-dom"
import { LuSearchCheck } from "react-icons/lu";
import { siteConfig } from "../../config/site-config"
import { cn } from "../../@/lib/utils"

export function MainNav() {
    const pathname = useLocation().pathname;

    return (
        <div className="mr-4"> {/* Hide on mobile "hidden md:flex" */}
            <Link to="/" className="mr-4 my-2 flex items-center space-x-2"> {/* Hide on mobile "lg:mr-6" */}
                <LuSearchCheck className="h-6 w-6 bg-primary text-primary-foreground rounded-md"/>
                <span className="font-bold "> {/* Hide on mobile "hidden lg:inline-block" */}
                    {siteConfig.name}
                </span>
            </Link>
            <nav className="flex items-center gap-4 text-sm lg:gap-6">
            <Link
                    to="/"
                    className={cn(
                        "transition-colors hover:text-foreground/80",
                        pathname === "/" ? "text-foreground" : "text-foreground/60"
                    )}
                >
                    Home
                </Link>
                <Link
                    to="/search"
                    className={cn(
                        "transition-colors hover:text-foreground/80",
                        pathname === "/search" ? "text-foreground" : "text-foreground/60"
                    )}
                >
                    Search
                </Link>
                <Link
                    to="/contact"
                    className={cn(
                        "font-sans transition-colors hover:text-foreground/80",
                        pathname?.startsWith("/contact") &&
                            !pathname?.startsWith("/contact")
                            ? "text-foreground"
                            : "text-foreground/60"
                    )}
                >
                    Contact
                </Link>
            </nav>
        </div>
    )
}