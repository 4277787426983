import { Button } from "../../@/components/ui/button";
import { LuSun } from "react-icons/lu";
import { LuMoon } from "react-icons/lu";
import { MainNav } from "./main-nav";
import { useState } from "react";

enum SiteStyle {
	Light = 'light',
	Dark = 'dark',
};

function getStyle(): SiteStyle {
	// Prefer saved style first
	const savedTheme = localStorage.getItem('theme');
	if (savedTheme === SiteStyle.Light || savedTheme === SiteStyle.Dark) {
        return savedTheme as SiteStyle;
    }

	// Then prefer window default
	if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
        return SiteStyle.Dark;
    }

	// Then default to light theme
    return SiteStyle.Light;
}


export function SiteHeader() {
	const [siteStyle, setSiteStyle] = useState<SiteStyle>(getStyle);

	if (siteStyle === SiteStyle.Light) {
		document.body.classList.remove("dark");
	} else if (siteStyle === SiteStyle.Dark) {
		document.body.classList.add("dark");
	}

	const setLightMode = () => {
		setSiteStyle(SiteStyle.Light);
		localStorage.setItem('theme', SiteStyle.Light);
		document.body.classList.remove("dark");
	}

	const setDarkMode = () => {
		setSiteStyle(SiteStyle.Dark);
		localStorage.setItem('theme', SiteStyle.Dark);
		document.body.classList.add("dark");
	}

	return (
		<header className="sticky top-0 z-50 w-full border-border/40 bg-background/95 backdrop-blur supports-[backdrop-filter]:bg-background/60">
			<div className="container flex h-14 max-w-screen-2xl items-center">
				<MainNav />
				<div className="flex flex-1 items-center justify-between space-x-2 md:justify-end">
					<div className="w-full flex-1 md:w-auto md:flex-none">
					</div>
					<nav className="flex items-center">
						{/* End of header items go here */}
						{siteStyle === SiteStyle.Light ? (
							<Button variant='ghost' size='icon' onClick={setDarkMode}>
								<LuSun></LuSun>
							</Button>
						) : (
							<Button variant='ghost' size='icon' onClick={setLightMode}>
								<LuMoon></LuMoon>
							</Button>
						)}

					</nav>
				</div>
			</div>
		</header>
	)
}
