function Contact() {
    return (
        <div className='container relative'>
            <h1 className="text-2xl text-center pb-5">Contact</h1>
            <div className="space-y-5">
                <p className="indent-3">
                    Hi, I'm Trevor from SLC Utah.
                    I'm the developer of Search TMS.
                    As a long time listener of TMS I wanted a better way to search the TMS backlog.
                    That's why I built Search TMS.
                    I have really enjoyed building the site and finding lost show clips.
                    I hope you enjoy using the site as well!
                </p>

                <p className="indent-3">
                    Are you enjoying the site?
                    Want to report a bug?
                    Have ideas of how the site could improve?
                    Interested in searching other podcasts?
                    Send me an email!
                    You can contact me by emailing <span className="font-bold">admin@searchtms.com</span>.
                </p>
            </div>

        </div>
    );
};
export default Contact;