import { Link } from "react-router-dom";
import { Button } from "../@/components/ui/button";


export function Home() {
    return (
        <div className="container relative">
            <h1 className="text-2xl text-center pb-5">Welcome to Search TMS!</h1>
            <div className="space-y-3">
                <p className="indent-3 pb-3">
                    This site was made by Trevor, a TMS listener in Utah.
                    Search TMS will allow you to search the backlog of <a className="underline" href="https://www.frogpants.com/tms">The Morning Stream</a>.
                    Almost all TMS episodes are included in the archive, excluding episodes made after December 2023.
                    You can search the archive using semantic similarity to what was said during an episode.
                    Semantic search allows searching the backlog with non-exact phrasing of topics allowing you to find audio clips.
                </p>

                <div>
                    <span className="">Examples of suggested search style:</span>
                    <ul className="list-disc pl-6">
                        <li className="italic">Getting hit by the blue shell in Mario Cart</li>
                        <li className="italic">Put the hot dog in the middle of the tortilla</li>
                        <li className="italic">3D printer broken again</li>
                    </ul>
                    <p>
                        Once you have made the search you will be able to listen to associated audio clips.
                    </p>
                </div>

                <p>
                    This site was made by a TMS listener wanting to make it easier to unlock the history of what has been said when in podcasts.
                    If you enjoy this site or have feed back <Link to="/contact" className="underline">please let me know!</Link>
                </p>

                <Link to="/search" className="pt-6 flex justify-center">
                    <Button className="text-xl h-16 px-10" size={"lg"}>
                        Start Searching!
                    </Button>

                </Link>
            </div>
        </div>
    )
}