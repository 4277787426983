import { SearchResult } from '../../types/openapi-client';
import moment from 'moment';
import 'moment-timezone';

import AudioPlayer, { PlayerHandle } from '../audio-player/audio-player';
import { Card } from '../../@/components/ui/card';
import { Button } from '../../@/components/ui/button';
import { useRef } from 'react';

interface SearchResultProps {
    searchResult: SearchResult
    currentPlayingId: string | null;
    setCurrentPlayingId: (id: string | null) => void;
}

function Result(result: SearchResultProps) {
    const searchResult = result.searchResult;
    const formattedDate = moment(searchResult.realTime).tz(moment.tz.guess()).format('MMMM Do YYYY');
    const hStart = humanTime(searchResult.startTime);
    const hEnd = humanTime(searchResult.endTime);
    const src = "/api/v1/episodeAudio?episodeId=" + searchResult.episodeId;

    const childRef = useRef<PlayerHandle>();

    const onStartTimeClick = () => {
        if (childRef.current != null) { childRef.current.resetCurrentTimeToStartTime() };
    }

    return (
        <Card className='py-5 px-1 md:p-5  md:space-y-3 space-y-2'>
            <div className='container flex'>
                <p className='font-semibold flex flex-1 w-auto justify-start'>{searchResult.episodeTitle}</p>
                <p className='font-semibold flex flex-1 w-auto justify-end'>{formattedDate}</p>
            </div>
            <div className='flex justify-center text-sm font-medium'>
                <Button variant={"ghost"} className={'px-2'} onClick={onStartTimeClick}>{hStart}</Button>
                <p className='h-9 py-2'>{"-"}</p>
                <p className='h-9 py-2 px-2'>{hEnd}</p>
            </div >
            <div className=' ml-6 mr-6 rounded-md border border-input bg-transparent px-3 py-1 text-sm shadow-sm transition-colors'>
                <p className='text-lg font-light'>{searchResult.text}</p>
            </div>
            <div>
                <AudioPlayer
                    src={src}
                    startSecond={searchResult.startTime}
                    duration={searchResult.episodeDuration}
                    ref={childRef}
                    id={searchResult.segmentId}
                    currentPlayingId={result.currentPlayingId}
                    setCurrentPlayingId={result.setCurrentPlayingId}
                />
            </div>
        </Card>
    )
}

function humanTime(t: number) {
    const m = Math.floor(t / 60);
    const s = Math.floor(t % 60);

    let sPretty;

    if (s === 0) {
        sPretty = "00";
    } else if (s < 10) {
        sPretty = "0" + s;
    } else {
        sPretty = s;
    }

    return m + ":" + sPretty;
}

export default Result;