import { SetStateAction, useState } from 'react';
import { DefaultApi as PodSearchAPI, SearchPostRequest, SearchMethod, ConfigurationParameters, Configuration, SearchResult } from "./../../types/openapi-client"
import Result from './result';
import { Input } from '../../@/components/ui/input';
import { Button } from '../../@/components/ui/button';
import { cn } from '../../@/lib/utils';

const configurationParams: ConfigurationParameters = {
    basePath: "/api/v1",
}

const configuration: Configuration = new Configuration(configurationParams);

const api = new PodSearchAPI(configuration);

function Search() {
    const [inputValue, setInputValue] = useState('');
    const [resultsList, setResultsList] = useState<SearchResult[]>([]);
    const [currentPlayingId, setCurrentPlayingId] = useState<string | null>(null);
    const [isWaitingOnSearch, setIsWaitingOnSearch] = useState<boolean>(false);


    const handleInputChange = (e: { target: { value: SetStateAction<string>; }; }) => {
        setInputValue(e.target.value);
    };

    const handleInputEnter = (event: { key: string; }) => {
        if (event.key === 'Enter') {
            doSearch();
        }
    }
    async function doSearch() {
        setResultsList([]);
        setIsWaitingOnSearch(true);

        const req: SearchPostRequest = {
            query: inputValue,
            show: 10,
            method: SearchMethod.DocSparse
        }

        try {
            const resp = await api.searchPost(req);
            setIsWaitingOnSearch(false);
            setResultsList(resp.data);
        } catch (error) {
            console.error(error);
        }

    }

    return (
        <div className='container pt-5'>
            <div className="flex flex-row max-w space-x-2 items-center justify-center pb-9">
                <Input value={inputValue} onChange={handleInputChange} onKeyUp={handleInputEnter} type="text" />
                <Button type="submit" onClick={doSearch} className={cn(isWaitingOnSearch ? "animate-pulse": "")}>Search</Button>
            </div>
            <div className='space-y-6'>
                {resultsList.map((item) => (
                    <Result key={item.segmentId} searchResult={item} currentPlayingId={currentPlayingId} setCurrentPlayingId={setCurrentPlayingId} />
                ))}
            </div>
        </div>
    )
}

export default Search;