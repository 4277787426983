/* tslint:disable */
/* eslint-disable */
/**
 * Text Search API
 * An API for searching based on a text string and returning a list of results, each containing a text value and a date.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * 
 * @export
 * @interface EpisodeAudioGet400Response
 */
export interface EpisodeAudioGet400Response {
    /**
     * 
     * @type {string}
     * @memberof EpisodeAudioGet400Response
     */
    'message'?: string;
}
/**
 * Search method
 * @export
 * @enum {string}
 */

export const SearchMethod = {
    Lexical: 'lexical',
    Dense: 'dense',
    Sparse: 'sparse',
    DocSparse: 'docSparse'
} as const;

export type SearchMethod = typeof SearchMethod[keyof typeof SearchMethod];


/**
 * 
 * @export
 * @interface SearchPost400Response
 */
export interface SearchPost400Response {
    /**
     * 
     * @type {string}
     * @memberof SearchPost400Response
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface SearchPost500Response
 */
export interface SearchPost500Response {
    /**
     * 
     * @type {string}
     * @memberof SearchPost500Response
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface SearchPostDefaultResponse
 */
export interface SearchPostDefaultResponse {
    /**
     * 
     * @type {string}
     * @memberof SearchPostDefaultResponse
     */
    'error'?: string;
}
/**
 * 
 * @export
 * @interface SearchPostRequest
 */
export interface SearchPostRequest {
    /**
     * The text string to search for
     * @type {string}
     * @memberof SearchPostRequest
     */
    'query': string;
    /**
     * The show id
     * @type {number}
     * @memberof SearchPostRequest
     */
    'show': number;
    /**
     * Result limit
     * @type {number}
     * @memberof SearchPostRequest
     */
    'limit'?: number;
    /**
     * 
     * @type {SearchMethod}
     * @memberof SearchPostRequest
     */
    'method': SearchMethod;
}


/**
 * 
 * @export
 * @interface SearchResult
 */
export interface SearchResult {
    /**
     * Index of the search in the source transcription
     * @type {number}
     * @memberof SearchResult
     */
    'index': number;
    /**
     * The real time the segment occured
     * @type {string}
     * @memberof SearchResult
     */
    'realTime': string;
    /**
     * Start time of the segment in the transcription
     * @type {number}
     * @memberof SearchResult
     */
    'startTime': number;
    /**
     * End time fo the segment in the transcription
     * @type {number}
     * @memberof SearchResult
     */
    'endTime': number;
    /**
     * The id of the specific segment
     * @type {string}
     * @memberof SearchResult
     */
    'segmentId': string;
    /**
     * The title of the episode
     * @type {string}
     * @memberof SearchResult
     */
    'episodeTitle': string;
    /**
     * The episode ID
     * @type {string}
     * @memberof SearchResult
     */
    'episodeId': string;
    /**
     * The episode duration in seconds
     * @type {number}
     * @memberof SearchResult
     */
    'episodeDuration': number;
    /**
     * The show ID
     * @type {string}
     * @memberof SearchResult
     */
    'showId': string;
    /**
     * The text of the segment
     * @type {string}
     * @memberof SearchResult
     */
    'text': string;
}

/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 
         * @param {string} episodeId The episodeId to download
         * @param {string} [range] The range of bytes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        episodeAudioGet: async (episodeId: string, range?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'episodeId' is not null or undefined
            assertParamExists('episodeAudioGet', 'episodeId', episodeId)
            const localVarPath = `/episodeAudio`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (episodeId !== undefined) {
                localVarQueryParameter['episodeId'] = episodeId;
            }

            if (range != null) {
                localVarHeaderParameter['Range'] = String(range);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns a list of search results. 
         * @summary Search based on text string
         * @param {SearchPostRequest} [searchPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchPost: async (searchPostRequest?: SearchPostRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(searchPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 
         * @param {string} episodeId The episodeId to download
         * @param {string} [range] The range of bytes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async episodeAudioGet(episodeId: string, range?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.episodeAudioGet(episodeId, range, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.episodeAudioGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Returns a list of search results. 
         * @summary Search based on text string
         * @param {SearchPostRequest} [searchPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchPost(searchPostRequest?: SearchPostRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SearchResult>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchPost(searchPostRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.searchPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * 
         * @summary 
         * @param {string} episodeId The episodeId to download
         * @param {string} [range] The range of bytes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        episodeAudioGet(episodeId: string, range?: string, options?: any): AxiosPromise<File> {
            return localVarFp.episodeAudioGet(episodeId, range, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns a list of search results. 
         * @summary Search based on text string
         * @param {SearchPostRequest} [searchPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchPost(searchPostRequest?: SearchPostRequest, options?: any): AxiosPromise<Array<SearchResult>> {
            return localVarFp.searchPost(searchPostRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * 
     * @summary 
     * @param {string} episodeId The episodeId to download
     * @param {string} [range] The range of bytes
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public episodeAudioGet(episodeId: string, range?: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).episodeAudioGet(episodeId, range, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns a list of search results. 
     * @summary Search based on text string
     * @param {SearchPostRequest} [searchPostRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public searchPost(searchPostRequest?: SearchPostRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).searchPost(searchPostRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



