function Notes() {
    return (
        <div className='container relative'>
            <h1 className="text-2xl text-center pb-5">Notes on Search TMS</h1>
            <div className="space-y-5">
                <p>
                    Thanks goes to Scott and Brian for making a podcast that has lasted over a decade!
                    Ultimately the audio and words are owned by them.
                    This site only makes searching the backlog a little more accessible.
                </p>

                <p className="text-md">
                    This site may cease to exist at anytime.
                </p>
            </div>

        </div>
    );
};
export default Notes;