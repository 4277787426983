import { useState, useRef, useEffect, useCallback, forwardRef, useImperativeHandle } from 'react';
import { MdForward10, MdReplay10, MdPauseCircleOutline, MdPlayCircleOutline } from "react-icons/md";
import { Slider } from '../../@/components/ui/slider';
import { Button } from '../../@/components/ui/button';
import { debounce } from 'lodash';


interface AudioPlayerProps {
    src: string
    startSecond: number
    duration: number
    id: string
    currentPlayingId: string | null;
    setCurrentPlayingId: (id: string | null) => void;
}

export type PlayerHandle = {
    resetCurrentTimeToStartTime: () => void;
};

function humanTime(t: number) {
    let m = Math.floor(t / 60).toString();
    m = (m !== "0" ? m : "00");

    let s = Math.floor(t % 60).toString();
    if (s.length === 1) {
        s = "0" + s;
    }

    return m + ":" + s;
}

let wasPlayingBeforeSliderChange = false;

const AudioPlayer = forwardRef((props: AudioPlayerProps, ref) => {
    const [trackSecond, setTrackSecond] = useState(0);
    const [audioLoaded, setAudioLoaded] = useState(false);
    const audioRef = useRef<HTMLAudioElement>(new Audio());

    const [isBuffering, setIsBuffering] = useState(false);

    const handleWaiting = () => {
        setIsBuffering(true);
    };

    const handleCanPlay = () => {
        setIsBuffering(false);
    };

    const playing = (): Boolean => {
        return props.currentPlayingId === props.id;
    };

    // Setup start time when clip loads
    useEffect(() => {
        if (audioRef.current !== null) {
            audioRef.current.currentTime = props.startSecond;
        }
        setTrackSecond(props.startSecond)
    }, [props.startSecond, audioRef.current])

    // Play the clip if it's the currently selected id
    useEffect(() => {
        if (audioRef.current) {
            if (props.currentPlayingId === props.id) {
                let playPromise = audioRef.current.play();
                playPromise.then(_ => {
                    // Playback has started
                }).catch(error => {
                    // Auto-play was prevented
                })
            } else {
                audioRef.current.pause();
            }
        }

    }, [props.currentPlayingId, props.id]);

    const handlePlayPauseClick = () => {
        if (playing()) {
            props.setCurrentPlayingId(null);
        } else {
            setAudioLoaded(true); // Trigger the load of the file
            props.setCurrentPlayingId(props.id);
        }
    }

    const debouncedProgressAudioRefUpdate = useCallback(
        debounce((newValue: number | number[]) => {
            audioRef.current.currentTime = newValue as number;
            if (wasPlayingBeforeSliderChange) {
                props.setCurrentPlayingId(props.id);
                wasPlayingBeforeSliderChange = false;
            }
        }, 250), []
    );

    const handleProgressChange = (newValue: number | number[]) => {
        if (playing()) {
            wasPlayingBeforeSliderChange = true;
        }
        props.setCurrentPlayingId(null);
        setTrackSecond(newValue as number)
        debouncedProgressAudioRefUpdate(newValue);
    }

    const handleSkipBackward = () => {
        audioRef.current.currentTime = audioRef.current.currentTime - 10;
        setTrackSecond(Math.floor(audioRef.current.currentTime));
    }

    const handleSkipForward = () => {
        audioRef.current.currentTime = audioRef.current.currentTime + 10;
        setTrackSecond(Math.floor(audioRef.current.currentTime));
    }

    const playAnimationRef = useRef(0);

    const updateWhilePlaying = useCallback(() => {
        if (audioRef.current !== null) {
            // Math.floor to force to integer from float
            const currentTime = Math.floor(audioRef.current.currentTime)
            setTrackSecond(currentTime);
            playAnimationRef.current = requestAnimationFrame(updateWhilePlaying);
        }
    }, [audioRef]);

    useEffect(() => {
        if (playing()) {
            playAnimationRef.current = requestAnimationFrame(updateWhilePlaying);
        } else {
            cancelAnimationFrame(playAnimationRef.current);
        }
    }, [props.currentPlayingId, props.id, audioRef, updateWhilePlaying]);


    useImperativeHandle(ref, () => ({
        resetCurrentTimeToStartTime() {
            handleProgressChange(props.startSecond);
        }
    }));

    let iconElement;
    if (isBuffering) {
        iconElement = <>
            <Button variant="default" size='icon' className='rounded-full animate-pulse ' onClick={handlePlayPauseClick}>
                <MdPauseCircleOutline className='h-9 w-9' />
            </Button>
        </>
    } else if (playing()) {
        iconElement = <>
            <Button variant="default" size='icon' className='rounded-full' onClick={handlePlayPauseClick}>
                <MdPauseCircleOutline className='h-9 w-9' />
            </Button>
        </>
    } else {
        iconElement = <>
            <Button variant="outline" size='icon' className='rounded-full' onClick={handlePlayPauseClick}>
                <MdPlayCircleOutline className='h-9 w-9' />
            </Button>
        </>
    }


    return (
        <>
            <div className='flex justify-center pb-1 space-x-2'>
                <Button variant="outline" size='icon' className='rounded-full' onClick={handleSkipBackward}>
                    <MdReplay10 className='h-9 w-9' />
                </Button>
                {iconElement}
                <Button variant="outline" size='icon' className='rounded-full' onClick={handleSkipForward}>
                    <MdForward10 className='h-9 w-9' />
                </Button>
            </div>
            <div className='flex justify-center'>
                <p className='flex text-sm font-semibold pr-2'>{humanTime(trackSecond)}</p>
                <Slider
                    min={0}
                    max={props.duration}
                    value={[trackSecond]}
                    step={1}
                    onValueChange={handleProgressChange}
                    className='hover:cursor-grab active:cursor-grabbing'
                />
                <p className='flex text-sm font-semibold pl-2'>{humanTime(props.duration)}</p>
            </div>
            {audioLoaded ? (
                <audio
                    src={props.src}
                    ref={audioRef}
                    onWaiting={handleWaiting}
                    onCanPlay={handleCanPlay}
                />
            ) : (
                <audio
                    ref={audioRef}
                />
            )}

        </>
    )
})
export default AudioPlayer