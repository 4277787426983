import { Link } from "react-router-dom";

export function SiteFooter() {
    return (
      <footer className="py-6 md:px-8 md:py-0">
        <div className="container flex flex-col items-center justify-between gap-4 md:h-24 md:flex-row">
          <p className="text-balance text-center text-sm leading-loose text-muted-foreground md:text-left">
            Built by Trevor in Utah, a TMS listener.
            <span className="pl-1 transition-colors hover:text-foreground/80">
              <Link to="/notes">Other notes.</Link>
            </span>
          </p>

        </div>
      </footer>
    )
  }
  