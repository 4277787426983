import './App.css';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Contact from "./components/contact";
import Search from "./components/search/search";
import { SiteHeader } from './components/layout/site-header';
import { SiteFooter } from './components/layout/site-footer';
import { Home } from './components/home';
import Notes from './components/notes';


function App() {
  return (
    <Router>
      <div className='flex flex-col h-screen justify-between'>
        <SiteHeader />
        <main className='flex-1 relative'>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/search" element={<Search />} />
            <Route path="/notes" element={< Notes />} />
          </Routes>
        </main>
        <SiteFooter />
      </div>
    </Router>
  );
}
export default App;
